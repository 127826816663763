// 获取用户信息
import { httpDelete, httpGet, httpPost, httpPut } from "@/api/request";
import $store from "@/store";

// 用户列表
export function GET_USER_LIST(params) {
    return httpGet("/admin/users", params, {
        headers: {
            Authorization: `Bearer ${$store.getters.getToken}`
        }
    });
}

export function ADD_USER(params) {
    return httpPost("/admin/users", params, {
        headers: {
            Authorization: `Bearer ${$store.getters.getToken}`
        }
    });
}

export function EDIT_USER(params) {
    return httpPut(`/admin/users/${params.id}`, params, {
        headers: {
            Authorization: `Bearer ${$store.getters.getToken}`
        }
    });
}

// 角色列表
export function GET_ROLE_LIST(params) {
    return httpGet("/admin/roles", params, {
        headers: {
            Authorization: `Bearer ${$store.getters.getToken}`
        }
    });
}

export function ADD_ROLE(params) {
    return httpPost("/admin/roles", params, {
        headers: {
            Authorization: `Bearer ${$store.getters.getToken}`
        }
    });
}

export function EDIT_ROLE(params) {
    return httpPut(`/admin/roles/${params.id}`, params, {
        headers: {
            Authorization: `Bearer ${$store.getters.getToken}`
        }
    });
}

export function DELETE_ROLE(params) {
    return httpDelete(`/admin/roles/${params.id}`, params, {
        headers: {
            Authorization: `Bearer ${$store.getters.getToken}`
        }
    });
}

// 获取角色详情
export function GET_ROLE_DETAIL(params) {
    return httpGet(`/admin/roles/detail/${params.id}`, params, {
        headers: {
            Authorization: `Bearer ${$store.getters.getToken}`
        }
    });
}

// 获取全部角色
export function GET_ALL_ROLE_LIST(params) {
    return httpGet("/admin/all_role", params, {
        headers: {
            Authorization: `Bearer ${$store.getters.getToken}`
        }
    });
}

// 获取权限列表
export function GET_PERMISSION_LIST(params) {
    return httpGet("/admin/permissions", params, {
        headers: {
            Authorization: `Bearer ${$store.getters.getToken}`
        }
    });
}

// 删除权限
export function DELETE_PERMISSION(params) {
    return httpDelete(`/admin/permissions/${params.id}`, params, {
        headers: {
            Authorization: `Bearer ${$store.getters.getToken}`
        }
    });
}

// 获取全部权限
export function GET_ALL_PERMISSION_LIST(params) {
    return httpGet("/admin/all_permissions", params, {
        headers: {
            Authorization: `Bearer ${$store.getters.getToken}`
        }
    });
}
