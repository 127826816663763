<template>
    <div class="accountlist-page">
        <div class="tab-bar">
            <van-tabs v-model:active="tabActive" :animated="false" color="#DF5F0B" title-active-color="#DF5F0B">
                <van-tab replace title="新　增" to="/client/account/add" />
                <van-tab replace title="账号列表" to="/client/account/list" />
            </van-tabs>
        </div>
        <div v-for="(item, index) in dataList" class="list-item">
            <van-swipe-cell>
                <div class="information-group">
                    <p>
                        {{ item.name }}
                        【
                        <span v-for="(citem, cindex) in item.roles_node">
                            {{ citem.name }}
                            <template v-if="cindex + 1 !== item.roles_node.length">；</template>
                        </span>
                        】
                    </p>
                </div>
                <template #right>
                    <div class="slide-button-group">
                        <van-button :block="true" class="slide-button" color="#DF5F0B" square @click="toDetail(item)">
                            编辑
                        </van-button>
                        <!--<van-button :block="true" class="slide-button" color="#D43030" square>删除</van-button>-->
                    </div>
                </template>
            </van-swipe-cell>
        </div>
    </div>
</template>

<script setup>
import { onMounted, ref } from "vue";
import { GET_USER_LIST } from "@/api/permission";
import usePublic from "@/hooks/usePublic";

let { $router } = usePublic();

onMounted(() => {
    getDataList();
});

let tabActive = ref(1);

// 获取账号列表
let dataList = ref([]);

let getDataList = () => {
    GET_USER_LIST({
        limit: 10000
    }).then((res) => {
        dataList.value = res.data.data.list;
        console.log(res.data.data);
    });
};

// 前往详情
let toDetail = (detail) => {
    $router.push({
        name: "ClientAccountAdd",
        query: {
            detail: JSON.stringify(detail)
        }
    });
};
</script>

<style lang="scss" scoped>
.accountlist-page {
    min-height: 100vh;
    background: #f0f0f0;

    .tab-bar {
        position: sticky;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 9;
        margin-bottom: 20px;
    }

    .list-item {
        font-size: 14px;
        border-bottom: dashed 1px #f0f0f0;

        .information-group {
            min-height: 60px;
            padding: 0 15px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            background: #fff;

            .p1 {
                color: #43cf7c;
            }

            .p2 {
                color: #999;
            }
        }

        .slide-button-group {
            display: flex;
            flex-direction: row;

            .slide-button {
                height: 60px;
            }
        }
    }
}
</style>
